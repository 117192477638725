import React, { useState, useEffect, useContext } from "react";
import Block from "../components/Block";
import BigTimeline from "../components/BigTimeline";
import { motion, AnimatePresence } from "framer-motion";
import { AnimationContext } from "../App";

type EventType = {
  date: string;
  sec_date: string;
  topic: string;
  sec_topic: string;
  shortText: React.ReactNode;
  fullText: React.ReactNode;
  category: string;
  showInSelected: boolean;
};

const Timeline = () => {
  const { homePageVisited, setHomePageVisited, blur, setBlur } = useContext(AnimationContext);
  const [selectedCategory, setSelectedCategory] = useState<string>("Selected");
  const [animateEvents, setAnimateEvents] = useState(true);
  const [dropdownOpen, setDropdownOpen] = useState(false); // State for dropdown visibility

  useEffect(() => {
    if (!homePageVisited) {
      setHomePageVisited(true);
    }
  }, [homePageVisited, setHomePageVisited]);

  const events: EventType[] = [
    {
      date: "Sept 2014",
      sec_date: "Juni 2022",
      topic: "Hertzhaimer-Gymnasium Trostberg",
      sec_topic: "German Abitur (University Entrance Qualification)",
      shortText: (
        <div>
          <p className="">
            <span className="font-semibold">Grade:</span> 1.9 &nbsp;
            <span>{"(1.0 best, 4.0 pass)"}</span>
          </p>
          <p>
            <span className="font-semibold">Activities: </span>
            Member of the Student Council (SMV)
          </p>
        </div>
      ),
      fullText: <div>More details </div>,
      category: "Education",
      showInSelected: true,
    },
    {
      date: "Aug 2021",
      sec_date: "Juni 2022",
      topic: "Member of the Student Council (SMV)",
      sec_topic: "Hertzhaimer-Gymnasium Trostberg",
      shortText: <p></p>,
      fullText: <div>Organizing of school related events.</div>,
      category: "Extracurriculars",
      showInSelected: false,
    },
    {
      date: "Jul 2022",
      sec_date: "Aug 2022",
      topic: "Working Student, Dyneon GmbH",
      sec_topic: "Traunreut, Germany",
      shortText: <p>Data visualization with Microsoft Power BI.</p>,
      fullText: <div>More details </div>,
      category: "Business",
      showInSelected: true,
    },
    {
      date: "Mar 2024",
      sec_date: "",
      topic: "Internship at Agile Robots",
      sec_topic: "",
      shortText: <div>test</div>,
      fullText: <div>More details </div>,
      category: "Education",
      showInSelected: true,
    },
    {
      date: "Sep 2024",
      sec_date: "",
      topic: "Exchange Semester at UCSD",
      sec_topic: "",
      shortText: <div>test</div>,
      fullText: <div>More details </div>,
      category: "Business",
      showInSelected: true,
    },
  ];
  const categories = [
    "Selected",
    ...Array.from(new Set(events.map((event) => event.category))),
  ];
  const filteredEvents =
    selectedCategory === "Selected"
      ? [
          ...events.filter((event) => event.showInSelected),
          {
            date: "",
            sec_date: "",
            topic: "Today",
            sec_topic: "",
            shortText: <div></div>,
            fullText: <div></div>,
            category: "",
            showInSelected: true,
          },
        ]
      : [
          ...events.filter((event) => event.category === selectedCategory),
          {
            date: "",
            sec_date: "",
            topic: "Today",
            sec_topic: "",
            shortText: <div></div>,
            fullText: <div></div>,
            category: "",
            showInSelected: true,
          },
        ];

  const [selectedEvent, setSelectedEvent] = useState<number | null>(null);
  return (
    <div className="w-full h-full pt-4">
      <motion.div className="w-full h-full" animate={{ filter: blur ? "blur(5px)" : "blur(0px)" }}>
      
        <Block variant="primary" className="p-4">
          <div className="w-full flex justify-end">
            <div className="relative inline-block text-left w-56">
              <div>
                <button
                  type="button"
                  onClick={() => setDropdownOpen(!dropdownOpen)}
                  className="inline-flex justify-between w-full rounded-xl px-4 py-2 bg-secondaryBlock text-sm font-medium text-secondary hover:shadow-[0_0_5px_white]"
                  id="menu-button"
                  aria-expanded={dropdownOpen}
                  aria-haspopup="true"
                >
                  {selectedCategory}
                  {/* Arrow Icon */}
                  <svg
                    className={`ml-2 h-5 w-5 transform transition-transform ${
                      dropdownOpen ? "rotate-180" : "rotate-0"
                    }`}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.23 7.21a.75.75 0 011.06.02L10 12.94l3.71-5.71a.75.75 0 111.06 1.06l-4.25 6.25a.75.75 0 01-1.06 0L5.23 8.27a.75.75 0 01.02-1.06z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </div>
              <AnimatePresence>
                {dropdownOpen && (
                  <motion.div
                    className="origin-top-right z-10 absolute left-0 mt-2 w-56 rounded-xl shadow-[0_0_10px_black] bg-secondaryBlock "
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="menu-button"
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: 50, scale: 0.1 }}
                    transition={{ duration: 0.1, ease: "easeOut" }}
                  >
                    <div className="p-1" role="none">
                      {categories.map((category) => (
                        <button
                          key={category}
                          onClick={(e) => {
                            e.preventDefault();
                            setSelectedCategory(category);
                            setDropdownOpen(false);
                            setAnimateEvents(false);
                          }}
                          className="flex px-4 py-2 text-sm w-full justify-start text-secondarySec hover:shadow-[0_0_5px_black] rounded-xl "
                          role="menuitem"
                        >
                          {category}
                        </button>
                      ))}
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </div>

          <BigTimeline
            setBlur={setBlur}
            setSelectedEvent={setSelectedEvent}
            events={filteredEvents}
            animateEvents={animateEvents}
          />
        </Block>
      </motion.div>
      {selectedEvent !== null && (
        <Modal
          event={filteredEvents[selectedEvent]}
          onClose={() => {
            setSelectedEvent(null);
            setBlur(false);
          }}
          index={selectedEvent}
        />
      )}
    </div>
  );
};

type ModalProps = {
  event: EventType;
  onClose: () => void;
  index: number;
};

const Modal: React.FC<ModalProps> = ({ event, onClose, index }) => {
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        onClose();
      }
    };
    document.body.style.overflow = "hidden"; // Prevent background scrolling
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      document.body.style.overflow = "auto";
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [onClose]);

  return (
    <AnimatePresence>
      <motion.div
        className="fixed inset-0 z-10 flex items-center justify-center "
        onClick={onClose} // Close modal when clicking outside the content
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        {/* Overlay with semi-transparent background */}
        <div className="absolute inset-0 bg-black bg-opacity-50" />

        {/* Modal Content */}
        <motion.div
          className="relative w-full max-w-xl h-auto p-6 ml-6 mr-6 rounded-lg bg-secondaryBlock"
          layoutId={`event-${index}`} // Pass layoutId directly
          onClick={(e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()} // Prevent click inside modal from closing it
          initial={{ scale: 0.9, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          exit={{ scale: 0.9, opacity: 0 }}
          transition={{ duration: 0.3 }}
        >
          {/* Close Button */}
          <button
            className="absolute top-2 right-4 text-secondary hover:text-secondarySec"
            onClick={onClose}
          >
            &times;
          </button>
          {/* Event Content */}
          <motion.div className="grow">
            <h2 className="font-mono text-xl font-semibold text-secondary">
              {event.topic}
            </h2>
            <p className="text-lg font-serif font-extralight text-secondarySec">
              {event.sec_topic}
            </p>
            <div className="mt-4 text-base font-serif font-extralight text-secondarySec">
              {event.fullText}
            </div>
          </motion.div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default Timeline;
