import { ReactNode } from "react";
import { twMerge } from "tailwind-merge";
import { motion, MotionProps } from "framer-motion";

type BlockProps = MotionProps &
  React.HTMLAttributes<HTMLDivElement> & {
    className?: string;
    children?: ReactNode;
    variant?: "primary" | "secondary";
    animate?: boolean;
  };

const Block = ({
  className,
  children = "",
  variant = "primary",
  animate = false,
  ...rest
}: BlockProps) => {
  const initialStyles =
    variant === "primary"
      ? { backgroundColor: "#404040", color: "#FFF7ED", scale: 1.0 } // bg-neutral-700, text-orange-50
      : { backgroundColor: "#FFF7ED", color: "#262626", scale: 1.0 }; // bg-orange-50, text-neutral-800

  const hoverStyles =
    variant === "primary"
      ? { backgroundColor: "#FFF7ED", color: "#262626" } // bg-orange-50, text-neutral-800
      : { backgroundColor: "#404040", color: "#FFF7ED" }; // bg-neutral-700, text-orange-50

  return (
    <motion.div
      className={twMerge("rounded-lg h-full w-full", className)}
      style={initialStyles}
      whileHover={animate ? hoverStyles : undefined}
      whileTap={animate ? { scale: 0.9 } : undefined}
      transition={{ duration: 0.3, ease: "easeInOut" }}
      {...rest} // Spread additional props onto motion.div
    >
      {children}
    </motion.div>
  );
};

export default Block;
