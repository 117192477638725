// Home.tsx
import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import portrait from "../assets/portrait.png";
import Block from "../components/Block";
import ArrowIcon from "../components/ArrowIcon";
import Timeline from "../components/SmallTimeline";
import { twMerge } from "tailwind-merge";
import { motion } from "framer-motion";
import { AnimationContext } from "../App";
import { defaultSpringTransition } from "../utils";

interface InfoBlockProps {
  className?: string;
  animationsShouldPlay: boolean;
}

const InfoBlock: React.FC<InfoBlockProps> = ({
  className = "",
  animationsShouldPlay,
}) => (
  <Block variant="secondary" className={twMerge("", className)}>
    <div className="relative h-64">
      <motion.p className="text-2xl font-mono font-semibold absolute bottom-0 left-0 pb-8 pl-4">
        <motion.span
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            delay: animationsShouldPlay ? 1.8 : 0.2,
            duration: 1,
            ease: "easeOut",
          }}
        >
          Software Engineer
        </motion.span>
        <br />
        <motion.span
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            delay: animationsShouldPlay ? 2.1 : 0.5,
            duration: 1,
            ease: "easeOut",
          }}
        >
          with Passion for
        </motion.span>
        <br />
        <motion.span
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            delay: animationsShouldPlay ? 2.4 : 0.8,
            duration: 1,
            ease: "easeOut",
          }}
        >
          AI and Maths
        </motion.span>
      </motion.p>
    </div>
  </Block>
);

interface PortraitBlockProps {
  className?: string;
}

const PortraitBlock: React.FC<PortraitBlockProps> = ({ className = "" }) => (
  <Block
    className={twMerge(
      "flex justify-center items-center h-64 w-full overflow-hidden",
      className
    )}
  >
    <img
      src={portrait}
      alt="Portrait"
      className="w-auto grayscale object-cover h-64"
    />
  </Block>
);

interface ProjectsBlockProps {
  className?: string;
  animationsShouldPlay: boolean;
}

const ProjectsBlock: React.FC<ProjectsBlockProps> = ({
  className = "",
  animationsShouldPlay,
}) => (
  <Link to="/projects" className={twMerge(" h-64", className)}>
    <Block
      variant="secondary"
      className="relative pl-4 pr-6 pb-6 flex flex-col justify-end h-full"
    >
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{
          delay: animationsShouldPlay ? 1.7 : 0.2,
          duration: 2,
        }}
      >
        <ArrowIcon className="w-8 h-8 absolute top-6 right-6" />
      </motion.div>

      <motion.p
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{
          delay: animationsShouldPlay ? 1.7 : 0.2,
          duration: 2,
        }}
        className="text-sm font-serif font-extralight"
      >
        Hi, I'm Christian, a passionate computer science student based
        in Munich. I'm always exploring innovative technology and
        problem-solving approaches, constantly looking for new ways to apply my
        skills in the digital world.
      </motion.p>
    </Block>
  </Link>
);

interface ContactBlockProps {
  className?: string;
  animationsShouldPlay: boolean;
}

const ContactBlock: React.FC<ContactBlockProps> = ({
  className = "",
  animationsShouldPlay,
}) => (
  <Link to="/contact" className={twMerge(" h-64", className)}>
    <Block
      variant="primary"
      className="relative pt-6 pl-4 pr-6 pb-6 flex flex-col justify-between h-full"
    >
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{
          delay: animationsShouldPlay ? 1.8 : 0.2,
          duration: 1.5,
          ease: "easeOut",
        }}
        className="w-8 h-8 absolute top-6 right-6"
      >
        <ArrowIcon />
      </motion.div>

      <motion.p
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{
          delay: animationsShouldPlay ? 1.8 : 0.2,
          duration: 1.5,
          ease: "easeOut",
        }}
        className="text-xs font-thin"
      >
        Want to <br />
        colaborate?
      </motion.p>
      <motion.p
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{
          delay: animationsShouldPlay ? 2.4 : 0.8,
          duration: 1,
          ease: "easeOut",
        }}
        className="text-3xl font-mono font-thin"
      >
        Contact Me
      </motion.p>
    </Block>
  </Link>
);

interface TimelineBlockProps {
  className?: string;
  animationsShouldPlay: boolean;
}

const TimelineBlock: React.FC<TimelineBlockProps> = ({
  className = "",
  animationsShouldPlay,
}) => (
  <Link to="/timeline">
    <Block
      variant="secondary"
      className={twMerge("p-6 grow max-h-[444px]", className)}
    >
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{
          delay: animationsShouldPlay ? 1.8 : 0.2,
          duration: 1,
          ease: "easeOut",
        }}
        className="relative pb-3 text-neutral-800"
      >
        <p className="text-xl font-serif">Timeline</p>
        <ArrowIcon className="w-8 h-8 absolute top-0 right-0" />
      </motion.div>
      <div className="overflow-y-auto max-h-[360px]">
        <Timeline animationsShouldPlay={animationsShouldPlay} />
      </div>
    </Block>
  </Link>
);

interface SocialLinksBlockProps {
  className?: string;
  animationsShouldPlay: boolean;
}

const SocialLinksBlock: React.FC<SocialLinksBlockProps> = ({
  className = "",
  animationsShouldPlay,
}) => (
  <Block variant="secondary" className={twMerge("p-6", className)}>
    <div className="text-sm font-serif font-extralight flex justify-between">
      <motion.a
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{
          delay: animationsShouldPlay ? 1.8 : 0.2,
          duration: 1,
          ease: "easeOut",
        }}
        href="https://www.linkedin.com/in/christian-remmelberger/"
        className="hover:underline"
      >
        LinkedIn
      </motion.a>
      <motion.a
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{
          delay: animationsShouldPlay ? 2.1 : 0.5,
          duration: 1,
          ease: "easeOut",
        }}
        href="https://github.com/chriremm"
        className="hover:underline"
      >
        Github
      </motion.a>
      <motion.a
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{
          delay: animationsShouldPlay ? 2.4 : 0.8,
          duration: 1,
          ease: "easeOut",
        }}
        href="https://www.instagram.com/chri.remm/"
        className="hover:underline"
      >
        Instagram
      </motion.a>
    </div>
  </Block>
);

const Home: React.FC = () => {
  const { homePageVisited, setHomePageVisited } = useContext(AnimationContext);

  const animationsShouldPlay = !homePageVisited;

  useEffect(() => {
    if (!homePageVisited) {
      setHomePageVisited(true);
    }
  }, [homePageVisited, setHomePageVisited]);

  return (
    <div>
      <motion.div className="grid gap-4 grid-cols-3 pt-4">
        <div className="col-span-3 lg:col-span-2 grid grid-cols-6 gap-4 items-center">
          <motion.div
            initial={
              animationsShouldPlay
                ? { scale: 0.7, opacity: 0, x: 150, y: 0 }
                : { scale: 0.7, opacity: 0, x: 100, y: 50 }
            }
            animate={{ scale: 1, opacity: 1, x: 0, y: 0 }}
            transition={{
              delay: animationsShouldPlay ? 1.5 : 0,
              duration: 0.5,
              type: "spring",
              stiffness: 50,
              damping: 10,
            }}
            className="col-span-6 md:col-span-4"
          >
            <InfoBlock animationsShouldPlay={animationsShouldPlay} />
          </motion.div>

          <motion.div
            initial={
              animationsShouldPlay
                ? {
                    x: -50,
                    y: 50,
                    z: 50,
                    scale: 1.3,
                  }
                : false
            }
            animate={
              animationsShouldPlay
                ? {
                    x: [-50, -50, -50, 0],
                    y: [50, 50, 50, 0],
                    z: [50, 50, 50, 0],
                    scale: [1.3, 1, 1, 1],
                  }
                : false
            }
            transition={{
              duration: 1.5,
              delay: 0.5,
              times: [0, 0.3, 0.7, 1],
              ease: "easeOut",
            }}
            className="col-span-6 md:col-span-2 h-full w-full relative z-30"
          >
            <motion.div
              initial={
                animationsShouldPlay
                  ? false
                  : { scale: 0.7, opacity: 0, x: 0, y: 50 }
              }
              animate={{ scale: 1, opacity: 1, x: 0, y: 0 }}
              transition={{
                delay: animationsShouldPlay ? 1.5 : 0,
                duration: 0.5,
                type: "spring",
                stiffness: 50,
                damping: 10,
              }}
            >
              <PortraitBlock />
            </motion.div>
          </motion.div>

          <motion.div
            initial={
              animationsShouldPlay
                ? { scale: 0.7, opacity: 0, x: 100, y: -100 }
                : { scale: 0.7, opacity: 0, x: 100, y: -30 }
            }
            animate={{ scale: 1, opacity: 1, x: 0, y: 0 }}
            transition={defaultSpringTransition(animationsShouldPlay ? 1.5 : 0)}
            className="col-span-6 md:col-span-3 h-64"
          >
            <motion.div
              className="w-full h-full"
              initial={{ scale: 1 }}
              whileHover={{ scale: 1.03 }}
              transition={{ duration: 0.2, ease: "easeInOut" }}
            >
              <ProjectsBlock animationsShouldPlay={animationsShouldPlay} />
            </motion.div>
          </motion.div>

          <motion.div
            initial={
              animationsShouldPlay
                ? { scale: 0.7, opacity: 0, x: 0, y: -150 }
                : { scale: 0.7, opacity: 0, x: 20, y: -50 }
            }
            animate={{ scale: 1, opacity: 1, x: 0, y: 0 }}
            transition={defaultSpringTransition(animationsShouldPlay ? 1.5 : 0)}
            className="col-span-6 md:col-span-3 h-64"
          >
            <motion.div
              className="w-full h-full"
              initial={{ scale: 1 }}
              whileHover={{ scale: 1.03 }}
              transition={{ duration: 0.2, ease: "easeInOut" }}
            >
              <ContactBlock animationsShouldPlay={animationsShouldPlay} />
            </motion.div>
          </motion.div>
        </div>
        <div className="col-span-3 lg:col-span-1 flex flex-col gap-4">
          <motion.div
            initial={
              animationsShouldPlay
                ? { scale: 0.7, opacity: 0, x: -150, y: 0 }
                : { scale: 0.7, opacity: 0, x: -70, y: 30 }
            }
            animate={{ scale: 1, opacity: 1, x: 0, y: 0 }}
            transition={defaultSpringTransition(animationsShouldPlay ? 1.5 : 0)}
          >
            <motion.div
              className="w-full h-full"
              initial={{ scale: 1 }}
              whileHover={{ scale: 1.03 }}
              transition={{ duration: 0.2, ease: "easeInOut" }}
            >
              <TimelineBlock animationsShouldPlay={animationsShouldPlay} />
            </motion.div>
          </motion.div>
          <motion.div
            initial={
              animationsShouldPlay
                ? { scale: 0.7, opacity: 0, x: -100, y: -100 }
                : { scale: 0.7, opacity: 0, x: -70, y: -50 }
            }
            animate={{ scale: 1, opacity: 1, x: 0, y: 0 }}
            transition={defaultSpringTransition(animationsShouldPlay ? 1.5 : 0)}
          >
            <SocialLinksBlock animationsShouldPlay={animationsShouldPlay} />
          </motion.div>
        </div>
      </motion.div>
    </div>
  );
};

export default Home;
