import React, { useContext, useEffect } from "react";
import Block from "../components/Block";
import ContactForm from "../components/ContactForm";
import { FaLinkedin, FaGithub, FaInstagram } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { motion } from "framer-motion";
import { AnimationContext } from "../App";

const Contact = () => {
  const links = [
    {
      href: "https://www.linkedin.com/in/christian-remmelberger/",
      icon: FaLinkedin,
    },
    {
      href: "https://github.com/chriremm",
      icon: FaGithub,
    },
    {
      href: "https://www.instagram.com/chri.remm/",
      icon: FaInstagram,
    },
    {
      href: "mailto:contact@remmelberger.com",
      icon: MdEmail,
    },
  ];

  const { homePageVisited, setHomePageVisited } = useContext(AnimationContext);

  useEffect(() => {
    if (!homePageVisited) {
      setHomePageVisited(true);
    }
  }, [homePageVisited, setHomePageVisited]);
  return (
    <div>
      <div className="grid grid-cols-[1fr_100px] gap-4 pt-4">
        <motion.div
          initial={{ x: 0, y: -100, scale: 0.5, opacity: 0 }}
          animate={{ scale: 1, opacity: 1, x: 0, y: 0 }}
          transition={{
            delay: 0.2,
            duration: 0.5,
            type: "spring",
            stiffness: 50,
            damping: 10,
          }}
          className="col-span-2 lg:col-span-1"
        >
          <Block variant="primary">
            <ContactForm />
          </Block>
        </motion.div>

        <div className="col-span-2 lg:col-span-1 grid grid-cols-4 gap-4 items-center">
          {links.map(({href, icon: Icon}, index) => (
            <motion.a
            key={index}
            className="col-span-2 md:col-span-1 lg:col-span-4 h-full w-full"
            href={href}
            initial={{ x: 0, y: 1000, scale: 1, opacity: 0 }}
            animate={{ scale: 1, opacity: 1, x: 0, y: 0 }}
            transition={{
              delay: 0.4 + index * 0.2,
              duration: 0.5,
              type: "spring",
              stiffness: 50,
              damping: 10,
            }}
          >
            <Block
              animate={true}
              className="p-8 flex justify-center items-center h-full w-full" 
            >
              <Icon size={32} />
            </Block>
          </motion.a>
          ))}
          
        </div>
      </div>
    </div>
  );
};

export default Contact;
