import React from "react";
import Block from "../components/Block";

const Projects = () => {
  return (
    <div className="pt-4">
      <Block className="col-span-12 ">
        <h1 className="text-4xl">Projects</h1>
        <p>Hier sind meine Projekte aufgelistet...</p>
      </Block>
    </div>
  );
};

export default Projects;
