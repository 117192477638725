import React, { createContext, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Projects from "./pages/Projects";
import Timeline from "./pages/Timeline";
import Contact from "./pages/Contact";
import Home from "./pages/Home";
import Header from "./components/Header";

export const AnimationContext = createContext({
  homePageVisited: false,
  setHomePageVisited: (visited: boolean) => {},
  blur: false,
  setBlur: (blured: boolean) => {},
});

function App() {
  const [homePageVisited, setHomePageVisited] = useState(false);
  const [blur, setBlur] = useState(false);

  return (
    <AnimationContext.Provider value={{ homePageVisited, setHomePageVisited, blur, setBlur }}>
      <Router>
        <Header />
        <div className=" bg-neutral-900 px-4 ">
          <div className="mx-auto max-w-4xl">
            <Routes>
              
              <Route path="/" element={<Home />} />
              <Route path="/projects" element={<Projects />} />
              <Route path="/timeline" element={<Timeline />} />
              <Route path="/contact" element={<Contact />} />
            </Routes>
          </div>
        </div>
      </Router>
    </AnimationContext.Provider>
  );
}

export default App;
