import React from "react";
import { motion } from "framer-motion";

type TimelineProps = {
  animationsShouldPlay: boolean;
};

const Timeline: React.FC<TimelineProps> = ({ animationsShouldPlay }) => {
  const events = [
    { date: "Aug 2004", text: "Born in Bavaria, Germany" },
    { date: "Jul 2022", text: "Finished High School" },
    { date: "Oct 2022", text: "Start of CS at TUM" },
    { date: "Mar 2024", text: "Internship at Agile Robots" },
    { date: "Sep 2024", text: "Exchange Semester at UCSD" },
  ];

  return (
    <div className="overflow-y-auto">
      {events.map((event, index) => (
        <motion.div
          key={index}
          className="flex gap-x-3"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{
            delay: animationsShouldPlay ? 1.8 + index * 0.2 : index * 0.2,
            duration: 0.5,
          }}
        >
          {/* Icon */}
          <div className="relative after:absolute after:top-7 after:bottom-0 after:start-3.5 after:w-px after:-translate-x-[0.5px] after:bg-neutral-300">
            <div className="relative z-10 size-7 flex justify-center items-center">
              <div className="size-2 rounded-full  bg-neutral-400"></div>
            </div>
          </div>
          {/* End Icon */}

          {/* Right Content */}
          <div className="grow pt-0.5 pb-8">
            <h3 className="flex gap-x-1.5 font-mono text-sm font-semibold text-secondary">
              {event.date}
            </h3>
            <p className="text-sm font-serif font-extralight text-secondarySec">
              {event.text}
            </p>
          </div>
          {/* End Right Content */}
        </motion.div>
      ))}
      <motion.div
        className="flex gap-x-2"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: events.length * 0.2, duration: 0.5 }}
      >
        {/* Icon */}
        <div className="relative last:after:hidden">
          <div className="relative z-10 size-7 flex justify-center items-center">
            <div className="size-2 rounded-full bg-neutral-400"></div>
          </div>
        </div>
        {/* End Icon */}

        {/* Right Content */}
        <div className="grow pt-0.5 ">
          <h3 className="flex  font-mono text-sm font-semibold text-secondary">
            Today
          </h3>
        </div>
        {/* End Right Content */}
      </motion.div>
    </div>
  );
};

export default Timeline;
